import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  formDirtyFlag$ = new BehaviorSubject(false);
  simulationFormDirtyFlag$ = new BehaviorSubject(false);
  senarioFormDirtyFlag$ = new BehaviorSubject(false);

  constructor() { }

  /*
 * function is used for save alert popup manage
 */
  formDirtyFlagIndetify(state: boolean) {
    this.formDirtyFlag$.next(state);
  }

  /*
 * function is used for simulation alert popup manage
 */
  simulationFormDirtyIdentify(state: boolean) {
    this.simulationFormDirtyFlag$.next(state);
  }

  /*
 * function is used for scenario alert popup manage
 */
  scenarioFormDirtyIndetify(state: boolean) {
    this.senarioFormDirtyFlag$.next(state);
  }

  get theme(): string {
    return document.documentElement.getAttribute('theme');
  }

  set theme(name: string) {
    document.documentElement.setAttribute('theme', name);
  }
}
