export const ReferenceData = [
  { id: 'Formulary', name: 'Formulary' },
  { id: 'Contracts', name: 'Contracts' },
  { id: 'Market Share', name: 'Market Share' },
];

export const ScenarioType = [
  { id: 'Actual', name: 'Actual' },
  { id: 'Forecast', name: 'Forecast' },
];

export const ComparisonNumber = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
];

export const GrowthValues = [
  { id: 'UserProvided', name: 'User Provided' },
  { id: 'MonteCarloRange', name: 'Monte Carlo Range' },
];

export const DiscountType = [
  { id: 'Historical', name: 'Historical'},
  { id: 'New', name: 'New'},
];

export const ScenarioComparisonTableData: Object[] = [
  {
    PBMName: 'Rev Forecast 1 Price', FSS: 1074, '340B': 1021, Medicaid: 1049, OGAPricing: 1032, Tricare: 1258
  },
  {
    PBMName: 'Rev Forecast 2 Price', FSS: "$1,171.00", '340B': "$1,147.00", Medicaid: "$1,161.00", OGAPricing: "$1,471.00", Tricare: "$1,156.00"
  },
  {
    PBMName: 'Rev Forecast 3 Price', FSS: "No Impact", '340B': "No Impact", Medicaid: "No Impact", OGAPricing: "No Impact", Tricare: "No Impact"
  },
]


export const lineData: Object[] = [
  { x: "2018 Q3", y: 12.00, color: '#7851A9' },
  { x: "2019 Q3", y: 33.00, color: '#78B751' },
  { x: "2020 Q3", y: 51.00, color: '#7851A9' },
  { x: "2021 Q3", y: 46.00, color: '#78B751' },
  { x: "2022 Q3", y: 44.00, color: '#7851A9' },
  { x: "2023 Q3", y: 51.00, color: '#78B751' },
  { x: "2024 Q3", y: 67.00, color: '#7851A9' }
];

export const WaterfallData: Object[] = [
  { x: 'Gross Revenue', y: 8000, color: '#7851A9' },
  { x: 'BASE REBATE', y: -1500, color: '#78B751' },
  { x: 'MC Admin Fee', y: 4500, color: '#78B751' },
  { x: 'Net Revenue', y: 5000, color: '#7851A9' },
];

export const SideBarObject = [
  {
    path: "/user-management",
    label: "User Management",
    icon: "fa-solid fa-user",
  },
  {
    path: "/role-management",
    label: "Role Management",
    icon: "fa-solid fa-gear",
  },
];

export const UserData = [
  {
    EmployeeID: 1,
    UserName: "John Smith",
    Email: "john.smith@example.com",
    FirstName: "United States",
    LastName: "09/15/2023",
    Status: "Active",
  },
  {
    EmployeeID: 2,
    UserName: "Sarah Johnson",
    Email: "sarah.johnson@example.com",
    FirstName: "Canada",
    LastName: "08/20/2023",
    Status: "Inactive",
  },
  {
    EmployeeID: 3,
    UserName: "Michael Brown",
    Email: "michael.brown@example.com",
    FirstName: "Australia",
    LastName: "07/25/2023",
    Status: "Active",
  },
  {
    EmployeeID: 4,
    UserName: "Emily Davis",
    Email: "emily.davis@example.com",
    FirstName: "Germany",
    LastName: "10/01/2023",
    Status: "Inactive",
  },
  {
    EmployeeID: 5,
    UserName: "David Wilson",
    Email: "david.wilson@example.com",
    FirstName: "United Kingdom",
    LastName: "06/30/2023",
    Status: "Active",
  },
  {
    EmployeeID: 6,
    UserName: "Jessica Miller",
    Email: "jessica.miller@example.com",
    FirstName: "France",
    LastName: "09/10/2023",
    Status: "Active",
  },
  {
    EmployeeID: 7,
    UserName: "James Taylor",
    Email: "james.taylor@example.com",
    FirstName: "Japan",
    LastName: "08/05/2023",
    Status: "Inactive",
  },
];